import en from 'ra-language-english'

const englishMessages = {
  ra: {
    ...en?.ra, // default englist translation
    content: {
      maximumLimit: 'Up to 4000 characters.',
      welcome: 'Welcome to',
      dargloabal: 'DarGlobals’',
      network: 'Network of',
      agent: 'Agents.',
      realEstate: 'Are you passionate about Luxury Real Estate?',
      joinAgents:
        'Join Dar Global’s Network of Agents to get involved in the global luxury real estate market and experience a host of valuable benefits. Be part of the network by registering your details now.',
      contact: 'Contact Us',
      portal: 'AGENT PORTAL',
      notAgent: 'Not a DarGlobal registered agent yet?',
      registerFor: 'I am registering for',
      linkExpired: 'Sorry, the link has expired.',
      accessBlock: 'Sorry, you are not allowed to access this page directly.',
      home: 'Home',
      queries: 'For any queries, please contact us at:',
      E: 'E.',
      UAE: 'UAE T.',
      UK: 'UK T.',
      Spain: 'Spain T.',
      supportTeam: 'Agent Support Team',
      contactUs:
        'In the event your designated account manager is unreachable, contact us on the below details',
      shareContent: 'Hello, we are pleased to share with you the',
      documents: 'documents',
      on: 'on',
      projectName: 'Project Name:',
      location: 'Location:',
      startSize: 'Starting Size:',
      startPrice: 'Starting Price:',
      price: 'Price:',
      size: 'Size:',
      unitNo: 'UnitNo:',
      query: 'Should you have any query, please do not hesitate to reach me.',
      shareDoc: 'Share Documents',
      Notice: 'Notice',
      someDoc: '*Some documents may not be available to share.',
      prepareDoc: 'Preparing documents',
      notFound: 'Oops! Page Not Found',
      docNotFound: 'Oops! Document Not Found',
      404: 'Error code: 404',
      returnHome:
        "We can't seem to find the page you are looking for. Don't worry- you can continue your real estate journey by returning to the homepage.",
      docErrorHome: "We can't seem to find the document you are looking for.",
      united: 'United Kingdom',
      arab: 'United Arab emirates',
      spain: 'Spain',
      logoutMessage:
        'Changes are not saved. In order to save changes, move to the last screen and click submit. Do you still want to logout?',
      connect:
        'Log In to connect with your dedicated Business Development Manager!',
      loginAccess: 'Log in now to access exclusive features',
      booking: 'Effortless Booking',
      bookUnits: 'Book Units, Raise Invoices, and Check Booking Status',
      manageLeads: 'Manage Leads',
      uploadLeads: 'Upload or Create New Leads Contacts',
      deleteLead: 'Are you sure you would like to delete the lead?',
      deletedSuccess: 'Lead has been deleted successfully',
      deleteProspect: 'Are you sure you would like to delete the prospect?',
      prospectDeletedSuccess: 'Prospect has been deleted successfully',
      deleteUnit: 'Are you sure you want to delete this unit?',
      deltedUnitSuccess: 'You have successfully deleted the unit.',
      addMoreBuyer: 'Would you like to add more buyers to this booking?',
      setUpTokenAmount:
        'Set up token amount and share the token payment details with the customer.',
      totalUnits: 'Total Units',
      amountPayable: 'Amount Payable',
      secureReservation:
        'Upload the token payment proof to secure this reservation',
      copyAndSharePaymentLink:
        'Copy or share the payment link with the customer.',
      bookingHours: 'Complete booking within 4 hours',
      tokenProof:
        'Submit token proof & payment on time for a successful reservation. Booking fails if time limit is exceeded.',
      addUnits: 'Please add a unit to proceed.',
      chooseOffline: 'Please choose the offline payment method.',
      tokenAmount: 'Token Amount',
      accoutHolderName: 'Account Holder Name ',
      bankName: 'Bank Name',
      accountNumber: 'Account Number',
      IbanNumber: 'IBAN Number',
      SwiftCode: 'Swift Code',
      chequePayable: 'Cheque Payable',
      darGlobalLLC: 'DarGlobal LLC',
      removeName: 'Are you sure you want to remove',
      removeBuyer:
        'Removing this buyer will result in distribution of ownership of the unit(s) among the remaining buyer(s).',
      signedReservationForm: 'Signed Reservation Form successfully submitted.',
      reservationFormSubmitted:
        'Please collect the token immediately to secure the unit for your customer.',
      removeCustomer: 'Are you sure you want to remove',
      removeCustomerMsg:
        'By removing this buyer, the remaining buyer(s) will now have ownership of the unit(s).',
      bookingIncompletemsg:
        'Share bank details or token payment link with the customer to reserve units.',
      deletedCustomer: 'You have successfully removed the customer.',
      changesLost: 'Are you sure you want to leave?',
      innerLostContent: 'Details on this form will not be saved.',
      lostProgress: 'Booking Progress Details will not be saved.',
      bookingFailed: 'Booking Failed !',
      exceedFourhours:
        'Exceeded 4 hours booking time period. Please start a new booking.',
      signed: 'Signed',
      signature: 'Signature Pending',
      checkboxContent: 'Same residence details as previous buyer',
      DearValuedPartner: 'Dear Valued Partner,'
    },
    notification: {
      fileSizeCheck: 'File size cannot be more than 10MB.',
      fileTypeCheck:
        'Invalid file format. Please upload png, jpg, jpeg or pdf file formats only.',
      resetLink: 'A reset link has been sent to your email.',
      duplicate: 'Duplicate file not allowed.',
      logout: 'You have logged out successfully',
      attachmentError:
        'Please attach the signed agreement to complete the process.',
      attachmentExceed:
        'You have exceeded the attachment capacity. Please contact agents@darglobal.co.uk for assistance.',
      NoticeContent:
      'Kindly be informed that our Agent Portal is presently undergoing an upgrade. To facilitate unit reservations, track booking statuses, or add contacts, we kindly request you to contact your designated Relationship Manager. Should your Relationship Manager be unavailable, please reach out to us via email agents@darglobal.co.uk',  
      changesLost: 'Are you sure you want to leave ? Changes will be lost',
      deactivateMember: 'Member Successfully Deactivated.',
      activateMember: 'Member Successfully Activated.',
      unitRemove: 'Unit has been removed from wishlist successfully',
      loggedOut: 'Are you sure? You will be logged out.',
      noData: 'Data not found',
      uploadImage: 'Please upload an image',
      validFormat: 'Please select a valid file format (PNG, JPG, JPEG)',
      noProject: 'Project Id not found',
      noUnit: 'Unit Id not found',
      passwordChanged: 'Password has been changed successfully',
      saveChanges: 'Changes Saved Successfully',
      wishlisted: 'Unit has been wishlisted successfully',
      wishlistExit: 'Unit is already wishlisted',
      incorrectPassword:
        'Incorrect current password. Please enter the correct password.',
      profileSuccess: 'Profile Picture Updated Successfully',
      profileRemove: 'Profile Picture Removed Successfully',
      companyProfileSuccess: 'Company Profile Picture Updated Successfully',
      companyProfileRemove: 'Company Profile Picture Removed Successfully',
      memberCreate: 'Member Created Successfully.',
      memberUpdate: 'Member Updated Successfully.',
      unitSelected: 'Unit Selected',
      prospectCreate: 'Prospect Created Successfully',
      prospectUpdate: 'Changes Saved',
      uploadTokenProof: 'Please upload token payment proof.',
      invalidDetails: 'Invalid details. Please review the information.',
      selectSingleProject: 'Please select units from the same project.',
      primaryCustomer: 'Please select a primary customer',
      accountDetailsCopied: 'Account details copied.',
      PaymentLinkCopied: 'Payment link copied.',
      AccountDetailsCopied: 'Account details copied.',
      paymentLinkSentToCustomer:
        "Payment link sent to customer's email address.",
      accountDetailsSentToCustomer:
        "Account details sent to customer's email address.",
      autoFilledCustomer:
        'Please review the auto-filled customer information before submitting.',
      autoFilledLead:
        'Some details are auto-filled. Please verify all details before submitting.',
      ownershipPercentageExceed:
        'Ownership  percentage should be 100% for a single unit',
      changesMade: 'Changes made successfully',
      notAvailable: 'Not Available',
      pNoLongerAvailable: 'are no longer available now',
      sNoLongerAvailable: 'is no longer available now',
      registerCustomers:
        'Please register all the customers before moving forward',
      proceedToSigned: 'Proceed to upload the signed reservation form.',
      assignOwner: 'Please assign ownership percentage',
      tokenAdjusted: 'Token Amount Adjusted Successfully',
      unitnoToast: 'unit has',
      unitno_Toast: 'units have',
      removedUnitToast: 'been removed since it was not available.',
      kycverification: 'Payment proof and KYC is under validation',
    },
    validation: {
      selectOne: 'Please select one.',
      selectNationality: 'Please select nationality',
      chooseOne: 'Please choose any one.',
      selectAny: 'Please select any one.',
      enterCity: 'Please enter city',
      validInput: 'Please enter a valid input.',
      validNumber: 'Please enter a valid number.',
      validPassportNo: 'Passport Number is required.',
      validNationalId: 'National Id is required.',
      validCompanyName: 'Please enter company name.',
      validCompanyAddress: 'Please enter company address.',
      validCountry: 'Please select country.',
      selectOptions: 'Please select one or more options.',
      selectCountry: 'Please select the country of residence.',
      validMobileNo: 'Mobile No. is required. Please enter correct number.',
      validMoNumber: 'Please enter a valid mobile number.',
      validTelephoneNo: 'Please enter correct number.',
      validPostalCode: 'Postal Code is required.',
      validPassport: 'Please enter passport number.',
      validPoBox: 'P.O. Box is required.',
      mandatoryDoc:
        'This is a mandatory field. Please upload a valid document.',
      validTrade: 'Please enter a valid trade license number.',
      validTradeNo: 'Please enter trade licence number.',
      validtradeLicencedate:
        'Please enter trade licence expiry date in dd/mm/yyyy format.',
      validTradeCountry: 'Please select trade license country..',
      validbrokerageNo:
        "Please provide brokerage certificate or NA if you don't have any.",
      validTaxNo:
        "Please enter a tax registration number or NA if you don't have any.",
      validSwiftCode: 'Swift Code is required.',
      validDoc: 'Upload Document is required.',
      validCompanyID: 'Please upload company document.',
      pleaseChoose: 'Please choose.',
      validDate: 'Please provide a valid date.',
      validIssueDate: 'Please provide a valid issue date.',
      validExpiryDate: 'Please provide a valid expiry date.',
      validEmail: 'Please enter a valid email.',
      emailError: 'Email address needs to be entered.',
      emailIncorrect: 'Email is incorrect.',
      passwordError: 'Password is required.',
      confirmError: 'Confirm Password is required.',
      passwordCheck:
        'The password should be a combination of 6 to 15 upper case, lower case, numbers and special characters.',
      passwordMatch: 'Passwords do no match.',
      mandatoryField: 'This field is mandatory. Please enter a valid input.',
      validEmailAdd: 'Please enter a valid email address.',
      vaidRole: 'Please enter member role.',
      validDesignation: 'Please enter member designation.',
      validLastname: 'Please enter the last name as on passport.',
      validAuthorisedLastName:
        'Please enter the last name as it appears on passport.',
      validFirstname: 'Please enter the first name as on passport.',
      validAuthorisedFirstName:
        'Please enter the first name as it appears on passport.',
      validPrefix: 'Please select prefix.',
      validMobile: 'Please enter valid mobile number.',
      selectType: 'Please select contact type.',
      selectProspectType: 'Please select prospect type.',
      valid_first_name: 'First Name is required.',
      valid_last_name: 'Last Name is required.',
      valid_mobile_no: 'Mobile no. is required.',
      valid_email_address: 'Email Address is required.',
      valid_company_name: 'Company name is required.',
      valid_trade_no: 'Trade license number is required.',
      validAddress: 'Please enter address.',
      validPassporExpiry:
        'Please enter passport expiry date in dd/mm/yyyy format.',
      validIdxpiry: 'Please enter ID expiry date in dd/mm/yyyy format.',
      uploadPassport: 'Please attach a copy of passport.',
      uploadCompanyPassport: 'Attach a passport copy Of Owner/Shareholder.',
      uploadId: 'Please attach a copy of ID.',
      idNumber: 'Please enter ID number.',
      valuidTradeLicense: 'Please attach a copy of trade license.',
      validCompanyDocument: 'Please attach company document.',
      validuploadDoc: 'Please upload the signed reservation form.',
      valid_invoice: 'Please attach at least one invoice.',
      chooseContacts: 'Please select contacts to continue',
      validPostalPO: 'Please enter postal code / p.o.box.',
      signedReservationUpload: 'Please upload the signed reservation form.',
      characterlimitexceeded:
        'Character limit exceeded. Keep it below 4000 characters.',
    },
    placeholder: {
      explain:
        'Please explain any agreed terms with DarGlobal sales representative. We will ensure to amend it before the reservation form and purchase contacts are issued.',
      firstName: 'First Name',
      lastName: 'Last Name',
      nationality: 'Nationality',
      docName: 'Document Name',
      passport: 'Passport',
      nationalId: 'National ID',
      email: 'Email Address',
      password: 'Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      companyName: 'Company Name',
      companyEmail: 'Company Email',
      postalCode: 'Postal code or NA',
      poBox: 'P.O. Box or NA',
      city: 'City',
      country: 'Country',
      currency: 'Currency',
      enter: 'Enter',
      name: 'Enter Name',
      code: 'Enter Code',
      street: 'Street/Area',
      brokerageNo: 'Brokerage Certificate Number or NA ',
      taxNo: 'Tax Registration Number or NA ',
      swiftCode: 'Swift Code',
      prefix: 'Prefix',
      address: 'House No., Building, Street/Area.',
      loggedIn: 'Keep me logged in',
      selectCountry: 'Search or select Country',
      selectProject: 'Search or select Project Name',
      searchPdi: 'Search or select Destinations',
      salesSearch: 'Search by Salesperson name',
      requestDescription:
        'It would be great if you elaborate more about your Purpose of request',
      selected: 'Selected',
      salespersonSearch: 'Select Salesperson name',
      businessManager: 'My Business Development Manager',
    },
    fieldName: {
      city: 'City',
      tradeLicenceCountry: 'Trade Licence Country ',
      tradeLicenceNo: 'Trade Licence No',
      passportNo: 'passport Number',
      idNumber: 'Id Number',
      additionIdNumber: 'ID Number',
      accountName: "Account Holder's Name",
      accountNumber: 'Bank Account Number',
      bankName: 'Bank Name',
      branch: 'Bank Branch',
      bankAddress: 'Bank Address',
      currency: 'Currency',
      swiftCode: 'Swift Code',
      transferCode: 'Transfer Code',
      authrizedSignatory: 'Authorized Signatory for Corporate',
      designation: 'Designation',
      sortCode: 'Sort Code',
      iban: 'IBAN Number',
      ifsc: 'IFSC Code',
      others: 'Others',
      otherCode: 'Enter Other Code',
      otherCodeName: 'Enter Other Code Name',
      mobileNo: 'Mobile No.',
      alternateNo: 'Alternate Mobile No.',
      alternativeNo: 'Alternative Mobile No',
      emailAddress: 'Email Address',
      email: 'Email',
      nationality: 'Nationality',
      civilCardNo: 'Residence Civil card number',
      qatarIdNumber: 'Qatar Id Number',
      idType: 'ID Type',
      passport: 'Passport / National ID Number',
      idIssueDate: 'ID Issue Date',
      idExpiryDate: 'ID Expiry Date',
      additionalUploadLabel: 'Upload ID',
      additionalUploadComLabel: 'Upload Company Document',
      adminName: 'Name(Admin/Office Manager)',
      telephoneNo: 'Telephone No',
      mailingAddress: 'Mailing Address',
      postalCode: 'Postal Code',
      poBox: 'P.O. Box',
      country: 'Country',
      tradeCountry: 'Trade License Country',
      company: 'Company',
      companyName: 'Company Name',
      companyEmail: 'Company Email',
      companyAdminEmail: 'Company/Admin Email',
      createPassword: 'Create Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      tradeNo: 'Trade License Number',
      regCity: 'City of Registration',
      regCountry: 'Country of Registration',
      tradeIssueDate: 'Trade License Issue Date',
      tradeExpiryDate: 'Trade License Expiry Date',
      licensedOption: 'Licensed/Non-Licensed',
      licensed: 'Licensed',
      nonLicensed: 'Non-Licensed',
      brokerageNo: 'Brokerage Certificate Number',
      brokergeIssueDate: 'Brokerage Certificate Issue Date',
      brokergeExpiryDate: 'Brokerage Certificate Expiry Date',
      taxNo: 'Tax Registration Number',
      projectInterest: 'Project Destinations Interested',
      businessManager: 'My Business Development Manager',
      companyAddress: 'Company Address and Contact Details',
      address: 'Address',
      address1: 'Address Line 1',
      address2: 'Address Line 2',
      fullName: 'Full name (As per passport)',
      dob: 'Date of Birth',
      issueDate: 'Issue Date',
      expiryDate: 'Expiry Date',
      passportExpiryDate: 'Passport Expiry Date',
      idPlaceIssue: 'Place of ID Issue',
      jobDesignation: 'Job Designation',
      additionalDoc: ' Additional Documents',
      uploadAddDoc: 'Upload add Document',
      uploadDoc: 'Upload Document',
      password: 'Password',
      uploadSize: 'Upload size max 10MB',
      incorporation: 'Certificate of Incorporation',
      uploadPassport: 'Upload Passport/National ID with signature',
      uploadBrokerCertificate:'Upload Broker Certificate (FAL)',
      uploadNationalityId:'Upload Saudi Iqama',
      upload_Passport: 'Upload Passport',
      upload_Id: 'Upload Id',
      civilCard: 'Residence Civil Card',
      qatarId: 'Qatar Id',
      emiratesId:'Emirates ID',
      brokerage: 'Brokerage Certificate',
      vat: 'VAT Certificate',
      idCard: 'Broker ID Card',
      NA: 'NA',
      info: 'Information Submitted!',
      verifyDetails:
        'We are verifying your details and will send you the confirmation via Email.',
      forgotPassword: 'Forgot Password?',
      OR: 'OR',
      countryResidence: 'Country of Residence',
      startFrom: 'Starting from',
      filter: 'Filters',
      sort: 'Sort',
      appliedFilter: 'Applied Filters:',
      byCountry: 'By Country',
      byProject: 'By Project',
      price: 'Price',
      size: 'Size',
      propertyType: 'Property Type',
      bedroom: 'Bedroom',
      min: 'MIN',
      max: 'MAX',
      unit: 'Unit',
      units: 'Units',
      project: 'Project',
      status: 'Status',
      searchCounty: 'Search by Country',
      uploadPicture: 'Upload Picture',
      manage: 'Manage',
      contactDetails: 'Contact Details',
      role: 'Role',
      full_name: 'Full Name',
      changePassword: 'Change Password',
      showSizes: 'Show sizes in',
      PersonalDetails: 'Personal Details',
      preferences: 'Preferences',
      companyDetails: 'Company Details',
      autrhorisedDetails: 'Authorised Persons Details',
      additionalIdDetails: 'Additional ID details',
      additionalDocDetails: 'Additional Document Details',
      myTeam: 'My Team',
      authorizedName: 'Authorized Person Name',
      authorizedFirstName: 'Authorized First Name',
      authorizedLasttName: 'Authorized Last Name',
      company_address: 'Company Address',
      company_address_line1: 'Company Address Line 1',
      company_address_line2: 'Company Address Line 2',
      totalMember: 'Total Members',
      loadMore: 'Loading more...',
      name: 'Name',
      title_role: 'Title & Role',
      action: 'Action',
      startPrice: 'Starting Price',
      startArea: 'Starting Area',
      amenities: 'Amenities',
      location: 'Location',
      share: 'Share',
      availableUnit: 'Available Units',
      selectAll: 'Select All',
      area: 'Area',
      selectUnit: 'Select Unit(s)',
      UAE: 'UAE',
      UK: 'UK',
      Spain: 'Spain',
      changeReset: 'Change or reset your password',
      currentPassword: 'Current Password',
      forgot_password: 'Forgot your password?',
      paymentPlan: 'Payment Plans',
      installment: 'Installment',
      immediate: 'Immediate',
      dueOn: ' Due On',
      lat: 'Lat',
      lng: 'Lng',
      viewLocation: 'View Location',
      propertyDetails: 'Property Details',
      unitNo: 'Unit Number',
      type: 'Type',
      bua: 'BUA',
      totalArea: 'Total Area',
      plotArea: 'Plot Area',
      internalArea: 'Internal Area',
      balconyArea: 'Balcony Area',
      completionDate: 'Anticipated Completion Date',
      book: 'Book for Customer',
      unitPageBook: 'Book',
      call: 'Call',
      chat: 'chat',
      profile: 'Profile',
      help: 'Help & Support',
      memberDetails: 'Member Details',
      prefix: 'Prefix',
      firstName: 'First Name',
      lastName: 'Last Name',
      document: 'Documents',
      leadType: 'Lead Type',
      prospectType: 'Prospect Type',
      bookingStatus: 'By Booking Status',
      time: 'By Time',
      customDate: 'Select a custom date of your choice',
      percentPaid: 'By Percent Paid',
      nameSearch: 'Search By Name',
      startDate: 'Start Date',
      endDate: 'End Date',
      selectCustomer: 'Select Contact Type',
      selectProspectCustomer: 'Select prospect type',
      individual: 'Individual',
      idDetails: 'ID details',
      prospectForm: 'Prospect Form',
      passportInfo: 'Please enter all information as per passport',
      authorisedDetails: 'Authorized Person details',
      customerDetails: 'Customer Details',
      leadDetails: 'Lead Details',
      bookingHistory: 'Booking History',
      details: 'Details',
      teamPlaceholder:'Search by Name or Email or Mobile',
      projectSearch: 'Search by Project Name or Unit No.',
      bookingDetails: 'Booking Details',
      actions: 'Actions',
      salesDetails: 'Salesperson Details',
      salesName: 'Salesperson Name',
      bySales: 'By Salesperson',
      lastUpdated: 'Last Updated:',
      pending: 'Pending',
      completed: 'Completed',
      Cancelled: 'Cancelled',
      all: 'All',
      contactsFound: 'Contacts Found',
      bookingsFound: 'Bookings Found',
      paymentDetails: 'Payment Details',
      bookingDate: 'Booking Date',
      bookingId: 'Booking ID',
      payment: 'Payment Plan',
      buyersDetails: 'Buyers Details',
      primary: 'Primary Buyer',
      primaryOnly: 'Primary',
      jointBuyer: 'Joint Buyer',
      unitDetails: 'Unit Details',
      unitBook: 'Units Booked',
      unit_no: 'Unit No.',
      reservationForm: 'Reservation Form',
      signPending: 'Signature Pending',
      salesAgreement: 'Sales Purchase Agreement',
      signed: 'Signed',
      postalOrPO: 'Postal Code / P.O.BOX',
      bookStatus: 'Booking Status',
      percentagePaid: 'Percentage Paid',
      spaSign: 'SPA Signed',
      ownership: 'Assign Ownership Percentage',
      updateOwnership:
        'Update ownership percentage to reflect client agreed-upon terms.',
      reviewCustomer: 'Review Customer Information',
      reservationInfo:
        'Please take a moment to confirm if the reservation information is accurate.',
      chooseToken: 'Choose Token Payment Method',
      shareToken:
        'Share the token payment details with the customer to secure the booking.',
      attachToken: 'Attach Token Payment Proof',
      procureToken:
        'Please attach payment proof to procure token for this booking.',
      uploadForm: 'Upload Signed Reservation Form',
      signedCopy: 'Please attach the signed copy of the reservation form.',
      awaitingPay: 'Awaiting Payment Milestone',
      noPayment:
        'Client payment has not yet reached payment milestone to raise invoice.',
      paidOff: 'Your hard work has paid off.',
      invoiceRequested: 'Invoice already Requested',
      '10_Percent': 'You can now raise invoice on 10% Client Payment',
      '20_Percent': 'You can now raise invoice on 20% Client Payment',
      '10_Percent_raised':
        'Invoice already requested for this unit against 10% Client Payment',
      '20_Percent_raised':
        'Invoice already requested for this unit against 20% Client Payment',
      '10%_Completed': '10% Client Payment Completed',
      '20%_Completed': '20% Client Payment Completed',
      paymentDone: 'Booking Process Completed',
      clientPaymentDone: 'Client Payment Completed',
      raiseInvoice: 'Raise an Invoice',
      customerName: 'Customer Name',
      requestPurpose: 'Purpose of Request',
      description: 'Description (Optional)',
      attachInvoice: 'Attach Invoice',
      invoiceDetail: 'Attach Invoice Against Unit %{unit}',
      invoiceAgainst: 'Raising Invoice Against Unit #%{unit}',
      existing: 'Existing Contacts',
      new: 'New Customer',
      attachTradeLicence: 'Attach Trade License',
      attachPassportShareholder: 'Attach Passport Of Owner/Shareholder',
      uploadCompanyDocument: 'Upload Company Document',
      invoiceSubmit: 'Invoice Submitted!',
      invoiceRequest:
        'Your invoice request for Unit #%{unit} has been submitted successfully',
      basicDetails: 'Basic Details',
      residenceDetails: 'Residence Details',
      addAdditional: 'Add Additional ID',
      addAdditionalDoc: 'Add Additional Document',
      removeAdditional: 'Remove Additional ID',
      removeAdditionalDoc: 'Remove Additional Document',
      additionalidExpiryDate: 'Additional Expiry date',
      availableProject: 'Available Projects',
      pageCount: `%{startCount} - %{endCount} of %{totalCount} properties`,
      salesperson: 'Salesperson',
      savedUnit: 'Saved Units',
      company_name: 'company Name',
      alternateMobNo: 'Alternative Mobile No',
      postalPoBox: 'Postal Code / P.O. Box ',
      passportNumber: 'Passport Number',
      additionalDetails: 'Additional ID Details',
      date: 'Date',
      tradeLicenseNumber: 'Trade License Number ',
      authorisedPersonsDetails: 'Authorised Person’s  details',
      percentage: '%',
      uploaded: 'Uploaded',
      verification: 'Details are under verification.',
      '10_invoice': 'Raise invoice now at 10% client payment',
      '20_invoice': 'Raise invoice now at 20% client payment',
      '10_invoice_raised': 'Invoice Already Raised at 10% Client Payment',
      '20_invoice_raised': 'Invoice Already Raised at 20% Client Payment',
      invoice_pending: 'Invoice Process Pending',
      comment: 'Variation Comment (Optional)',
      cancelled: 'Booking Cancelled',
      unitcancelled: 'unit cancelled',
    },
    pageTitle: {
      companyDetails: 'Company Details',
      reservationSuccessfylly: 'Reservation Successful',
      companyPersonnel: 'Company Personnel’s',
      companyBankDetail: 'Company Bank Detail',
      authrizedSignatory: 'Authorized Signatory for Corporate',
      companyAddress: 'Company Address and Contact Details',
      uploadDocuments: 'Upload Documents',
      createAccount: 'Create Your Account',
      bankDetails: 'Add Bank Details',
      addresssDetails: 'Address & Contact Details',
      PersonalDetails: 'Personal Details',
      BrokerPortal: 'Broker Portal',
      passport: 'Passport',
      nationalId: 'National ID',
      forgotPassword: 'Forgot Password',
      resetPassword: 'Reset Password',
      projects: 'Projects',
      contacts: 'My Contacts',
      addContact: 'Add Contact',
      bookForCustomer: 'Book for Customer',
      reviewUnits: 'Review Units',
      assignOwnerShip: 'Assign Ownership Percentage',
      tokenPaymentDetails: 'Token Payment Details',
      adjustTokenPaymnet: 'Adjust Token Payment ',
      adjustTokenPaymnetProof: 'Attach Token Payment Proof',
      tokenSuccessfully: 'Token proof submitted successfully.',
      bookingComplete:
        'This Booking is complete, subject to token payment verification.',
      reservationDetails: 'Reservation Details',
      downloadReservationForm: 'Download Reservation Form',
      uploadSignedForm: 'Upload Signed Reservation Form',
      bookings: 'My Bookings',
      units: 'Units',
      accountDetails: 'Account Details',
      reservationForm: 'Reservation Form',
      ownershipBreakdown: 'Ownership Breakdown',
      chooseContact: 'Choose Contacts',
      bookingIncomplete: 'Booking Incomplete',
      reservationFormSigned: 'Reservation Form Signed',
      ReservationFormSubmitted: 'Reservation Form Submitted',
    },
    noData: {
      project: 'No Projects Found.',
      team: 'No Records Found.',
      soldOut: "We're Sold Out!",
      unit: 'No Units Available.',
      wishlist: 'No Saved Units Found.',
      contact: 'No Contacts Found.',
      booking: 'No Bookings Found',
      unitFound: 'No Units Found.',
      noprojectdatafound: 'No project data found in the response.',
      apiunsuccessfull: 'API request was not successful.',
      Errorfetchingprojectdetails: 'Error fetching project details',
    },
    button: {
      okay: 'Okay',
      next: 'Next',
      back: 'Back',
      goBack: 'Go Back',
      submit: 'Submit',
      logout: 'Logout',
      signIn: 'Sign In',
      register: 'Register Now',
      myself: 'Myself',
      agency: 'Agency',
      createAccount: 'Create Account',
      gridView: 'Grid view',
      listView: 'List view',
      apply: 'Apply',
      reset: 'Reset',
      view: 'View',
      unit: 'Units',
      project: 'Projects',
      cancel: 'Cancel',
      clear: 'Clear',
      removePic: 'Remove Picture',
      upload: 'Upload',
      change: 'Change',
      saveChange: 'Save Changes',
      addMember: 'Add Member',
      add: 'Add',
      edit: 'Edit',
      activate: 'Activate',
      deActivate: 'Deactivate',
      viewUnit: 'View Units',
      updatePassword: 'Update Password',
      yes: 'Yes',
      no: 'No',
      share: 'Share',
      save: 'Save',
      create: 'Create',
      update: 'Update',
      homePage: 'Go to Homepage',
      login: 'Login',
      continue: 'Continue',
      addLead: 'Add Lead',
      addProspect: 'Add Prospect',
      addUnits: 'Add Units',
      delete: 'Delete',
      close: 'Close',
      takeAction: 'Take Action',
      raiseInvoice: 'Raise Invoice',
      review: 'Review',
      copyLink: 'Copy Link',
      sendLink: 'Send Link',
      copyDetails: 'Copy Details',
      sendDetails: 'Send Details',
      gotoBookings: 'Go To My Bookings ',
      viewRservationForm: 'View Reservation Form',
      editCustomer: 'Edit Customer',
      deleteCustomer: 'Delete Customer',
      bookings: 'Go to My bookings',
      viewAccountDetails: 'View Account Details',
      exit: 'exit',
      exitBooking: 'Exit Booking',
      shareBooking: 'Share Details',
      remove: 'Remove',
      goHomePage: 'Go to homepage',
      closeWindow: 'Close Window',
      ok: 'Ok',
      addOtherUnit:'Add Other Unit',
      cancelBooking:'Cancel Booking',
    },
    apiError: {
      tryLater: 'Something went wrong, please try again later.',
      logout: 'Logging out due to token expiry.',
      accountRejected:
        'Seems like your account does not exist. Please register with us again.',
    },
  },
}

export { englishMessages }
